import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
// import Button from '@material-ui/core/Button';
import { CopyBlock } from "react-code-blocks";
// import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './mystyle.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
// import logo from './img/Free_Sample_By_Wix.png';
import htmr from 'htmr';

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  Result,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};


export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ results}) => ({ results })}>
        {({ results, }) => {
          return (
          <div >
            <div className="title">
              <br />
              <Jumbotron>
                {/*
                <div>
                  <Image src={logo} fluid />
                </div>
              */}
              <div>
                {"        "}
                <h1 align="middle">lang2code</h1>
                <p align='middle'> an NLP based code search tool</p>
              </div>
                <br />
                <div>
                  {/* <SearchBox autocompleteSuggestions={true} /> */}
                  <SearchBox
                    autocompleteSuggestions={true}
                    inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                      <>
                        <div className="sui-search-box__wrapper">
                          <input {...getInputProps({ placeholder: 'how to ... ' })} />
                          {getAutocomplete()}
                        </div>

                        <Button  className="Botton" type="submit" value="Search">
                          search
                        </Button>
                        
                      </>
                    )}
                  />
                </div>

              </Jumbotron>
            </div>
            <div className="title" >
              <div >
                    {results.map(result => (                        
                <pre>
                  <Card >
                    <Card.Header>
                      <Button variant="primary" size="sm" href={result.link.raw}>
                        Link
                      </Button>
                      {"  "}
                      {result.question.raw}
                      {"  "}
                      <Badge variant="secondary">
                      {result.main_tag.raw}
                      </Badge>

                    </Card.Header>
                    <Card.Body>
                        <SyntaxHighlighter language={result.main_tag.raw} style={ coy }>
                          {result.answer.raw}
                          
                          </SyntaxHighlighter>
                    </Card.Body>
                  </Card>
                </pre> 

                  ))}
              </div>
            </div>
            <footer className="footer">
              <Paging />
            </footer>
          </div>

          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}






// export default function App() {
//   return (
//     <SearchProvider config={config}>
//       <WithSearch mapContextToProps={({ results, wasSearched }) => ({ results, wasSearched })}>
//         {({ results, wasSearched }) => {
//           return (


//             <div className="App">
//               <ErrorBoundary>
//                 <Layout

//                   header={<SearchBox autocompleteSuggestions={true} />}
//                   // sideContent={
//                   //   <div>
//                   //     {wasSearched && (
//                   //       <Sorting
//                   //         label={"Sort by"}
//                   //         sortOptions={buildSortOptionsFromConfig()}
//                   //       />
//                   //     )}
//                   //     {getFacetFields().map(field => (
//                   //       <Facet key={field} field={field} label={field} />
//                   //     ))}
//                   //   </div>
//                   // }
//                   bodyContent={
//                     // <div>
//                     // {results.map(result => (
//                     //   <Result key={result.id.raw}
//                     //     result={result}
//                     //     titleField={getConfig().titleField}
//                     //     urlField={getConfig().urlField}
//                     //   />
//                     // ))}
//                     // </div>

//                     // <Results
//                     //   titleField={getConfig().titleField}
//                     //   urlField={getConfig().urlField}
//                     //   shouldTrackClickThrough={true}
//                     // />


//                     <div>
//                         // <div>
//                         //   hi!
//                         // </div>
//                       {results.map(result => (
//                         // <CopyBlock
//                         //   text={result.answer.raw}
//                         //   language={'python'}
//                         //   showLineNumbers={10}
//                         //   // wrapLines
//                         // />
//                         // <SyntaxHighlighter language="javascript" style={docco}>
//                         // <SyntaxHighlighter language="javascript" style={darcula}>
                        
//                           <SyntaxHighlighter style={darcula}>
//                             {result.answer.raw}
//                           </SyntaxHighlighter>
                        
//                         // <Button>
//                         // {result.answer.raw}
//                         // </Button>

//                         // <Result key={result.id.raw}
//                         //   result={result}
//                         //   // titleField="title"
//                         //   // urlField="nps_link"
//                         // />

//                       ))}
//                     </div>

//                     // <Results
//                     //   // titleField={getConfig().titleField}
//                     //   // urlField={getConfig().urlField}
//                     //   // shouldTrackClickThrough={true}
//                     //   renderResult={CodeResult}
//                     // />



//                   }
//                   bodyHeader={
//                     <React.Fragment>
//                       {wasSearched && <PagingInfo />}
//                       {wasSearched && <ResultsPerPage />}
//                     </React.Fragment>
//                   }
//                   bodyFooter={<Paging />}
//                 />
//               </ErrorBoundary>
//             </div>
//           );
//         }}
//       </WithSearch>
//     </SearchProvider>
//   );
// }

